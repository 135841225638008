const editState = {
    activeCell: [0, 0],
    selectionSize: [0, 0],
    clipboard: null,
}

const isInRange = (val, r1, r2) => {
    const min = Math.min(r1, r2);
    const max = Math.max(r1, r2);
    return min <= val && val <= max;
}

const isSelected = (pos) => {
    if (mode !== "edit") {
        return false;
    }
    const {activeCell, selectionSize} = editState;
    return (
        isInRange(pos[0], activeCell[0], activeCell[0]+selectionSize[0]) &&
        isInRange(pos[1], activeCell[1], activeCell[1]+selectionSize[1])
    )
}

const copy = () => {
    const gridOfValues = initialState.box.grid.map(row => row.filter(cell => isSelected(cell.pos)).map(cell => cell.value)).filter(row => row.length > 0);
    editState.clipboard = {
        grid: gridOfValues,
    }
    return editState.clipboard;
}

const clear = () => {
    const selectedCells = initialState.box.grid.flat().filter(cell => isSelected(cell.pos));
    selectedCells.forEach(cell => cell.value = "");
}

const cut = () => {
    const copied = copy();
    clear();
    return copied;
}

const paste = () => {
    clear();
    // TODO: make this work.
}

canvas.addEventListener("mousedown", ev => {
    if (mode !== "edit") {
        return;
    }
    const i = Math.floor((ev.offsetX - offsetX) / cellSize);
    const j = Math.floor((ev.offsetY - offsetY) / cellSize);
    editState.activeCell = [i, j];
    editState.selectionSize = [0, 0];
    draw();
});

canvas.addEventListener("mousemove", ev => {
    if (mode !== "edit") {
        return;
    }
    if (ev.buttons === 1) {
        const [i, j] = editState.activeCell;
        const ii = Math.floor((ev.offsetX - offsetX) / cellSize);
        const jj = Math.floor((ev.offsetY - offsetY) / cellSize);
        editState.selectionSize = [ii - i, jj - j];
        draw();
    }
});

window.addEventListener("keydown", ev => {
    if (mode !== "edit") {
        return;
    }
    ev.preventDefault();
    const selectedCells = initialState.box.grid.flat().filter(cell => isSelected(cell.pos));
    const setSelectedCells = (val) => {
        selectedCells.forEach(cell => cell.value = val);
    }
    if (ev.key.startsWith("Arrow")) {
        if (!ev.ctrlKey) {
            const {activeCell, selectionSize} = editState;
            const thingToMove = ev.shiftKey ? selectionSize : activeCell;
            if (ev.key === "ArrowUp") {
                thingToMove[1] -= 1;
            }
            if (ev.key === "ArrowDown") {
                thingToMove[1] += 1;
            }
            if (ev.key === "ArrowLeft") {
                thingToMove[0] -= 1;
            }
            if (ev.key === "ArrowRight") {
                thingToMove[0] += 1;
            }
        }
    } else if (ev.key === "Escape") {
        editState.selectionSize = [0, 0];
    } else if (ev.key === "Backspace" || ev.key === "Delete") {
        clear();
    } else if (selectedCells.length === 1) {
        const key = ev.key.toUpperCase();
        if (["L","R","<",">","/","\\",".","?"].includes(key))
        setSelectedCells(ev.key.toUpperCase());
    }
    draw();
});